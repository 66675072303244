export const stringToRegex = (input: string) => {
  try {
    const match = input.match(/^\/(.*?)\/([gimy]*)$/)
    if (!match) throw new Error('Invalid regular expression format.')

    const pattern = match[1]
    const flags = match[2]

    return new RegExp(pattern, flags)
  } catch (e) {
    console.log('parse regex error:', e)
    return null
  }
}
