import type { Ref } from 'vue'
import type { IManagerFormNodeValue, INodeField } from '@manager'

export const useFieldCalculation = (
  node: INodeField,
  parentValue: Ref<IManagerFormNodeValue>,
) => {
  const calculate = (value: unknown) => {
    // TODO: Implement calculation logic
    if (
      node.valueType === 'PERCENTAGE' &&
      value !== undefined &&
      value !== null
    ) {
      return Number(value) / 100
    }

    if (
      node.valueType === 'CURRENCY' &&
      value !== undefined &&
      value !== null
    ) {
      return Number(value)
    }

    return value ?? undefined
  }

  const setCalculated = (value: unknown) => {
    parentValue.value[`calculated_${node.name}`] = calculate(value)
    Object.defineProperty(parentValue.value, `calculated_${node.name}`, {
      enumerable: false,
    })
  }

  setCalculated(parentValue.value[node.name])

  return { calculate, setCalculated }
}
