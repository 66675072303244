import {
  FieldRequiredLevel,
  FieldValidationType,
  type INodeField,
} from '@manager'

export const useFieldValidation = (node: INodeField) => {
  const validation = computed(() => {
    const validation: any[][] = []

    // REQUIRED
    switch (node.requiredLevel) {
      case FieldRequiredLevel.REQUIRED:
        validation.push(['required'])
        break
      case FieldRequiredLevel.RECOMMENDED:
        validation.push(['?required'])
        break
    }

    switch (node.validationType) {
      case FieldValidationType.DECIMAL:
      case FieldValidationType.INTEGER:
      case FieldValidationType.PERCENTAGE: {
        validation.push(['number'])
        break
      }
    }

    if (node.validationRule) {
      const regex = stringToRegex(node.validationRule)
      if (regex) validation.push(['matches', regex])
    }

    return validation
  })

  const validationMessages = computed(() => {
    const messages: Record<string, string> = {}

    // REQUIRED
    if (node.requiredLevel === FieldRequiredLevel.RECOMMENDED) {
      messages.required = 'This field is recommended'
    }

    return messages
  })

  return {
    validation,
    validationMessages,
  }
}
