<script setup lang="ts">
import {
  FieldRequiredLevel,
  FieldUsage,
  joinPath,
  parseFieldType,
  parseSizeToGrid,
  resolveNodeId,
  useFieldValidation,
  useFieldCalculation,
  useFieldCast,
  useFieldCache,
} from '@manager'
import type {
  IManagerFieldEmits,
  IManagerFieldProps,
  IManagerFieldSlots,
} from './types'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldEmits>()

defineSlots<IManagerFieldSlots>()
const slots = useSlots()

const id = resolveNodeId(props.node)
const type = parseFieldType(props.node)
const colSize = computed(() => parseSizeToGrid(props.node.size))
const label = computed(() => {
  let label = props.node.label

  if (props.node.requiredLevel === FieldRequiredLevel.REQUIRED) {
    label += ' *'
  }

  return label
})

const path = computed(() => joinPath(props.parentPath, props.node.name))

const parentValue = useVModel(props, 'parentValue', emit)

let castPlugin, setCalculated
if (!slots.default) {
  const { cast, castPlugin: _castPlugin } = useFieldCast(props.node)
  castPlugin = _castPlugin

  // Cast value and fallback to default
  parentValue.value[props.node.name] = cast(
    parentValue.value[props.node.name],
    props.node.defaultValue,
  )

  // Initialize calculated value, after setting default value
  const { setCalculated: _setCalculated } = useFieldCalculation(
    props.node,
    parentValue,
  )
  setCalculated = _setCalculated
}

const { validation, validationMessages } = useFieldValidation(props.node)

if (!slots.default) {
  useFieldCache(props.node, parentValue)
}
</script>

<template>
  <!-- Field -->
  <div
    v-if="node.usage !== FieldUsage.HIDDEN"
    :class="colSize"
    :data-path="path"
  >
    <slot
      v-bind="{
        id,
        name: node.name,
        label,
        type,
        validation,
        validationMessages,
      }"
    >
      <FormKit
        :id="id"
        v-model="parentValue[node.name]"
        :type="type"
        :name="node.name"
        :label="label"
        label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
        :plugins="[castPlugin]"
        :validation="validation"
        :validation-messages="validationMessages"
        :min="node.min ?? undefined"
        :max="node.max ?? undefined"
        validation-visibility="live"
        @input="setCalculated"
      >
        <template v-if="node.prefix" #prefix>
          <span
            class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
          >
            {{ node.prefix }}
          </span>
        </template>
        <template v-if="node.suffix" #suffix>
          <span
            class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
          >
            {{ node.suffix }}
          </span>
        </template>
      </FormKit>
    </slot>
  </div>
</template>
