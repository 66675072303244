import type { IManagerFormNodeValue, INodeField } from '@manager'
import type { Ref } from 'vue'

export const useFieldCache = (
  node: INodeField,
  parentValue: Ref<IManagerFormNodeValue>,
) => {
  const __stored = ref<any>(null)
  const __storedCalculated = ref<any>(undefined)

  onActivated(() => {
    if (!parentValue.value[node.name] && __stored.value) {
      parentValue.value[node.name] = __stored.value
      parentValue.value[`calculated_${node.name}`] = __storedCalculated.value
    }
  })
  onDeactivated(() => {
    __stored.value = parentValue.value[node.name]
    __storedCalculated.value = parentValue.value[`calculated_${node.name}`]

    // Only set value to null if the key exists in the parentValue
    if (node.name in parentValue.value) {
      parentValue.value[node.name] = null
    }

    if (`calculated_${node.name}` in parentValue.value) {
      parentValue.value[`calculated_${node.name}`] = undefined
    }
  })
}
